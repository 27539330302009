.cms-o-nas, .cms-about-us {
    .page-title {
        background: url('../../images/city.jpg') no-repeat center center fixed;
        background-size: cover;
        width: 100%;
        margin: auto;
        padding: 6rem 1rem;
        text-align: center;

        @include mq(lg) {
            margin-top: 99px;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 72rem;
        margin: auto;
        padding: 4rem 1rem;
    }
}
