@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'vars';
@import 'mixins';
@import 'cms';
@import 'posts';

@import 'layout/page';
@import 'layout/nav';
@import 'layout/hamburger';
@import 'layout/footer';

@import 'pages/home';
@import 'pages/onas';
@import 'pages/kontakt';
