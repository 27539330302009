.cms-kontakt, .cms-contact {
    .top-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        max-width: 72rem;
        margin: auto;
        padding: 6rem 1rem;

        @include mq(sm) {
            flex-direction: row;
        }

        @include mq(lg) {
            margin-top: 99px;
        }
    }

    .map {
        width: 100%;
        margin-bottom: 6rem;

        iframe {
            width: 100%;
            min-height: 500px;
        }
    }

    .belt {
        background: url('../../images/belt1.jpg') no-repeat center center fixed;
        background-size: cover;
        width: 100%;
        margin: auto;
        padding: 6rem 1rem;
        text-align: center;

        h2 {
            color: white;
            font-size: 3rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        &--1 {
            background-image: url('../../images/belt1.jpg');
        }

        &--2 {
            background-image: url('../../images/belt2.jpg');
        }

        &--3 {
            background-image: url('../../images/belt3.jpg');
        }
    }

    .workers {
        display: grid;
        grid-template: auto / 1fr;
        gap: 1rem;
        width: 100%;
        max-width: 72rem;
        margin: auto;
        padding: 6rem 1rem;

        @include mq(sm) {
            &--2-cols {
                grid-template: auto / 1fr 1fr;
            }
        }

        .worker {
            display: flex;
            flex-direction: column-reverse;
            width: calc(100vw - 2rem);

            @include mq(sm) {
                flex-direction: row;
                width: 100%;
            }

            &__img-container {
                display: flex;
                align-items: center;
                justify-content: center;

                @include mq(sm) {
                    &--wide {
                        width: 50%;
                    }

                    &--slim {
                        width: 35%;
                    }
                }
            }

            &__content {
                flex-grow: 1;
                padding: 0 0 0.5rem 0;

                @include mq(sm) {
                    padding: 0.5rem 0 0.5rem 2rem;
                }
            }

            &__name {
                font-size: 1.7rem;
                font-weight: bold;
            }

            &__position {
                margin-bottom: 1rem;
            }

            &__info-row {
                display: flex;
                align-items: center;
                padding: 0.5rem 0;
            }

            &__icon-container {
                width: 40px;
                margin-right: 1rem;
            }

            &__icon {
                height: 30px;
                margin: auto;
            }
        }
    }
}
