.home-page-top {
    background: url('../../images/home_bg_top.png') no-repeat center bottom fixed;
    background-size: cover;

    @include mq(lg) {
        margin-top: 99px;
    }
}

.home-page-posts {
    width: 100%;
    max-width: 72rem;
    margin: auto;
    padding: 4rem 1rem;

    h2 {
        color: black;
        font-size: 1.6rem;
        font-weight: bold;
    }

    .posts {
        display: grid;
        grid-template: auto / 1fr;
        gap: 2rem;
        width: 100%;

        @include mq(lg) {
            grid-template: auto / 1fr 1fr;
        }
    }

    .post {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq(sm) {
            flex-direction: row;
        }

        &__img-container {

            @include mq(sm) {
                width: 50%;
            }
        }

        &__content {

            @include mq(sm) {
                width: 50%;
            }
        }

        &__title {
            font-size: 1.5rem;
            font-weight: 600;
        }

        &__short {
            font-size: 0.9rem;
            margin: 0.6rem 0;
        }

        &__more {
            font-size: 0.7rem;
            font-weight: 600;
        }
    }
}
