body {
    font-family: 'Spartan', sans-serif;
    margin: 0 auto;
    color: black;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    z-index: 11;

    @include mq(lg) {
        position: fixed;
        background: white;
        width: 100%;
    }
}
