.nav {
    padding-top: 50px;
    position: absolute;
    background: white;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 2;

    transform-origin: 0 0;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

    &--active {
        transform: translate(0, 0);
    }

    @include mq(lg) {
        padding-top: 0;
        position: static;
        background: none;
        height: auto;
        width: auto;
        transform: translate(0, 0);
    }

    .nav__list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        list-style: none;

        @include mq(lg) {
            flex-direction: row;
        }
    }

    .nav__item {
        width: 100%;

        &--selected {
            background: $secondary;

            .nav__link {
                color: #424242;
            }
        }

        @include mq(lg) {
            width: auto;
            font-weight: 600;
            text-transform: uppercase;
            font-size: 0.9rem;

            &:not(:first-child) {
                margin-left: 30px;
            }

            &--selected {
                position: relative;
                background: none;

                &:after {
                    content: '';
                    position: absolute;
                    height: 2px;
                    background: $primary;
                    bottom: 0;
                    left: 30%;
                    right: 30%;
                }

                .nav__link {
                    color: $primary;
                }
            }
        }
    }

    .nav__link {
        display: block;
        color: $primary;
        text-decoration: none;
        padding: 15px;

        @include mq(lg) {
            padding: 15px 0 10px 0;
        }
    }
}
