$breakpoints: (
  "xs": 475px,
  "sm": 640px,
  "md": 768px,
  "lg": 1024px,
  "xl": 1200px
);

@mixin mq($breakpoint) {
  $breakpoint: map-get($breakpoints, $breakpoint);
  @if ($breakpoint) {
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  }
}
